<template>
  <div
    class="w-full h-screen max-h-screen min-h-screen flex items-stretch fixed top-0 left-0 right-0 bottom-0 z-50"
    v-if="success"
  >
    <div class="md:w-2/3 w-full bg-realBlack flex items-center justify-center">
      <div class="relative min-w-[300px] max-w-[350px]">
        <div class="w-full flex justify-end md:hidden">
          <div
            @click="closeSuccess"
            class="relative cursor-pointer w-[16px] h-[16px]"
          >
            <span
              class="w-full absolute top-[8px] left-0 block h-[2px] bg-white rotate-45"
            ></span>
            <span
              class="w-full absolute top-[8px] left-0 block h-[2px] bg-white -rotate-45"
            ></span>
          </div>
        </div>
        <div class="checkMark flex items-center justify-center w-full">
          <svg
            width="202"
            height="202"
            viewBox="0 0 202 202"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M91.191 27.0925C96.9656 22.6185 105.036 22.6185 110.81 27.0925L117.535 32.3027C120.477 34.5817 124.12 35.7654 127.839 35.6506L136.342 35.3882C143.643 35.1629 150.172 39.9064 152.214 46.9202L154.592 55.0881C155.632 58.6607 157.884 61.7598 160.96 63.853L167.994 68.6386C174.033 72.748 176.527 80.4232 174.056 87.2978L171.179 95.3034C169.921 98.8051 169.921 102.636 171.179 106.137L174.056 114.143C176.527 121.018 174.033 128.693 167.994 132.802L160.96 137.588C157.884 139.681 155.632 142.78 154.592 146.353L152.214 154.521C150.172 161.534 143.643 166.278 136.342 166.053L127.839 165.79C124.12 165.675 120.477 166.859 117.535 169.138L110.81 174.348C105.036 178.822 96.9656 178.822 91.191 174.348L84.4663 169.138C81.5248 166.859 77.8817 165.675 74.1625 165.79L65.6595 166.053C58.358 166.278 51.8291 161.534 49.7871 154.521L47.4091 146.353C46.369 142.78 44.1174 139.681 41.041 137.588L34.0078 132.802C27.9683 128.693 25.4744 121.018 27.945 114.143L30.8222 106.137C32.0806 102.636 32.0806 98.8051 30.8222 95.3034L27.945 87.2978C25.4744 80.4232 27.9683 72.748 34.0078 68.6386L41.041 63.853C44.1174 61.7598 46.369 58.6607 47.4091 55.0881L49.7871 46.9202C51.8291 39.9064 58.358 35.1629 65.6595 35.3882L74.1625 35.6506C77.8817 35.7654 81.5248 34.5817 84.4663 32.3027L91.191 27.0925Z"
              fill="#50AF30"
            />
            <path
              d="M131.52 80.0597C132.85 83.4903 130.988 85.8396 127.387 88.1577C124.481 90.0222 120.78 92.0483 116.859 95.5069C113.012 98.8971 109.261 102.983 105.927 107.004C103.074 110.457 100.358 114.019 97.7852 117.685C96.4987 119.521 94.6995 122.262 94.6995 122.262C94.0538 123.26 93.1646 124.076 92.1158 124.634C91.067 125.193 89.8932 125.475 88.7052 125.453C87.5179 125.446 86.3521 125.136 85.3186 124.551C84.2852 123.966 83.4186 123.127 82.801 122.113C79.6967 116.902 77.304 114.841 76.2039 114.105C73.2612 112.122 69.8057 111.837 69.8057 107.225C69.8057 103.561 72.8976 100.594 76.7104 100.594C79.4046 100.693 81.9061 101.753 84.1248 103.244C85.5418 104.195 87.0427 105.457 88.6027 107.113C90.6932 104.26 92.8673 101.47 95.1221 98.7448C98.7174 94.4099 102.962 89.7581 107.515 85.7432C111.989 81.7968 117.163 78.102 122.648 76.1505C126.222 74.8765 130.193 76.626 131.52 80.0597Z"
              fill="white"
              stroke="white"
              stroke-width="2.09753"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="w-full mt-12 text-white">
          <p
            class="text-2xl font-semibold text-center font-inter"
            style="letter-spacing: 0.02em"
          >
            Order Submitted!
          </p>
          <p class="text-center text-xs mt-2 font-inter">
            Your order for Savannah has been received, and an email has been to
            you with the details of the order. <br />
            Our sales team will reach out to you via email within 72 hours
          </p>
        </div>
      </div>
    </div>
    <div
      class="w-1/3 md:flex hidden bg-white px-6 flex-col items-end justify-center"
    >
      <div
        @click="closeSuccess"
        class="relative cursor-pointer w-[16px] h-[16px]"
      >
        <span
          class="w-full absolute top-[8px] left-0 block h-[2px] bg-realBlack rotate-45"
        ></span>
        <span
          class="w-full absolute top-[8px] left-0 block h-[2px] bg-realBlack -rotate-45"
        ></span>
      </div>
      <div class="w-full">
        <div class="w-full mb-8">
          <h4
            class="font-inter text-[#222222] leading-[35px] tracking-[0.26px] text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)]"
          >
            Delivery Information
          </h4>
          <div class="mt-4 space-y-4 px-3">
            <div class="w-full">
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
              >
                Delivery Timeline
              </p>
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
              >
                Your vehicles will be delivered between 60 to 90 days.
              </p>
            </div>
            <div class="w-full">
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
              >
                Address
              </p>
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
              >
                {{
                  `${this.payload.street}, ${this.payload.city}, ${this.payload.state}`
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="">
          <h4
            class="font-inter text-[#222222] leading-[35px] tracking-[0.26px] text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)]"
          >
            Order Summary
          </h4>

          <div class="mt-4 space-y-4 px-3">
            <div class="between" v-show="!isOutright">
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
              >
                Down payment amount
              </p>
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
              >
                {{ downPayment }}
              </p>
            </div>
            <div class="between" v-show="isOutright">
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
              >
                Vehicle price
              </p>
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
              >
                {{ price | formatCurrency }}
              </p>
            </div>

            <div class="between">
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
              >
                Number of vehicles
              </p>
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
              >
                {{ number }}
              </p>
            </div>
            <div class="between">
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
              >
                Colour(s)
              </p>
              <div v-if="sho" class="w-4/5 flex-wrap gap-3 mt-1 end">
                <div
                  v-for="(select, index) in selected"
                  :key="index + 'selected' + select"
                >
                  <div>
                    <img
                      :src="spectrum[select - 1].icon"
                      alt=""
                      class="w-5 h-5 overflow-hidden rounded-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="between">
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
              >
                Order type
              </p>
              <p class="leading-[32px] font-inter" v-show="!isOutright">
                Finance
              </p>
              <p class="leading-[32px] font-inter" v-show="isOutright">
                Buy outright
              </p>
            </div>
            <div class="between">
              <p
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
              >
                Total
              </p>
              <p
                v-show="!isOutright"
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
              >
                {{ totDownPayment }}
              </p>
              <p
                v-show="isOutright"
                class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
              >
                {{ totPrice | formatCurrency }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full" v-else>
    <!-- PRELOAD -->
    <img
      src="@/assets/images/savannah/colors/black_cropped.png"
      class="fixed w-px h-px opacity-0 -z-10"
      alt=""
      ref="heroPic"
      @load="lhero = true"
    />
    <!-- PRELOAD -->
    <div
      v-if="!lhero"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen"
    >
      <div
        class="bg-[#1B1B1B] absolute load1 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div
        class="bg-[#1B1B1B] absolute load2 top-0 left-0 w-full h-screen z-0"
      ></div>
      <div class="img1">
        <img
          src="@/assets/images/miniLogo.png"
          class="z-[1] zoom img1"
          alt="Logo"
        />
      </div>
    </div>

    <div class="w-full" v-else>
      <section
        class="grid lg:grid-cols-2 mt-[90px] lg:mt-[129px] w-[90%] lg:w-[83%] max-w-xl mx-auto gap-x-32 pb-20 lg:pb-32"
      >
        <div>
          <div
            class="flex items-baseline justify-start space-x-[clamp(2.5rem,0.939vw+2.148rem,3.5rem)]"
          >
            <h4
              class="text-[clamp(1.5rem,0.469vw+1.324rem,2rem)] font-belgiano leading-[40px]"
            >
              Savannah
            </h4>
            <ul
              class="space-x-[clamp(2.5rem,0.939vw+2.148rem,3.5rem)] text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] list-disc start font-inter leading-[32px] text-black/60"
            >
              <li>SUV</li>
              <li>2024 Model</li>
            </ul>
          </div>

          <div class="mt-4 mb-8 space-x-2 flex justify-between w-[90%]">
            <div>
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] font-inter leading-[23px] text-[#4F4F51A6] text-opacity-65"
              >
                Top Speed
              </p>
              <p
                class="font-inter leading-[32px] text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
              >
                180km/H
              </p>
            </div>
            <div>
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] font-inter leading-[23px] text-[#4F4F51A6] text-opacity-65"
              >
                Range
              </p>
              <p
                class="font-inter leading-[32px] text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
              >
                460km
              </p>
            </div>
            <div>
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] font-inter leading-[23px] text-[#4F4F51A6] text-opacity-65"
              >
                Battery
              </p>
              <p
                class="font-inter leading-[32px] text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
              >
                68 kwh
              </p>
            </div>
            <div>
              <p
                class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] font-inter leading-[23px] text-[#4F4F51A6] text-opacity-65"
              >
                Charging
              </p>
              <p
                class="font-inter leading-[32px] text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
              >
                AC/DC
              </p>
            </div>
          </div>

          <img
            src="@/assets/images/savannah/colors/black_cropped.png"
            class="w-full py-10"
            alt="savannah"
          />

          <div class="mt-[clamp(1.5rem,0.939vw+1.148rem,2.5rem)]">
            <label>
              <span class="text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                >Numbers of cars</span
              >
              <input
                type="number"
                v-model="number"
                class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
              />
            </label>
            <div v-if="sho">
              <div
                class="mt-[clamp(1.75rem,0.704vw+1.486rem,2.5rem)]"
                v-for="(select, index) in selected"
                :key="'selected' + select"
              >
                <p
                  class="font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)] leading-[19.6px]"
                >
                  Preferred Color<span v-if="showNumbering">
                    {{ index + 1 }}</span
                  >:
                  <span>{{ spectrum[select - 1].name }}</span>
                </p>
                <div class="flex items-center w-full gap-3 mt-1">
                  <img
                    class="w-10 h-10 overflow-hidden rounded-full cursor-pointer"
                    v-for="(col, indx) in spectrum"
                    :class="[
                      select === indx + 1 ? 'border-[3px] border-green' : '',
                    ]"
                    @click="handleColourSelect(index, indx)"
                    :key="'sel' + indx + index"
                    :src="col.icon"
                    alt=""
                  />
                </div>
                <div
                  class="w-full flex items-center mt-4 gap-4"
                  v-if="showNumbering && index === 0"
                >
                  <span
                    class="inline-flex w-10 rounded-3xl cursor-pointer p-1"
                    @click="all = !all"
                    :class="[
                      all ? 'justify-end bg-green' : 'justify-start bg-grey',
                    ]"
                  >
                    <span
                      class="inline-block w-4 h-4 rounded-full bg-white"
                    ></span>
                  </span>
                  <span class="text-sm">Apply to all cars</span>
                </div>
              </div>
            </div>

            <div
              class="hidden lg:block border-t border-black border-opacity-[12.5%] pt-6 mt-6"
            >
              <h4
                class="font-inter text-[#222222] leading-[35px] tracking-[0.26px] text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)]"
              >
                Order Summary
              </h4>

              <div class="mt-4 space-y-4">
                <div class="between" v-show="!isOutright">
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
                  >
                    Down payment amount
                  </p>
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ downPayment }}
                  </p>
                </div>
                <div class="between" v-show="isOutright">
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
                  >
                    Vehicle price
                  </p>
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ price | formatCurrency }}
                  </p>
                </div>

                <div class="between">
                  <p
                    class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
                  >
                    Number of vehicles
                  </p>
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ number }}
                  </p>
                </div>
                <div class="between">
                  <p
                    class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
                  >
                    Colour(s)
                  </p>
                  <div v-if="sho" class="w-4/5 flex-wrap gap-3 mt-1 end">
                    <div
                      v-for="(select, index) in selected"
                      :key="index + 'selected' + select"
                    >
                      <div>
                        <img
                          :src="spectrum[select - 1].icon"
                          alt=""
                          class="w-5 h-5 overflow-hidden rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="between">
                  <p
                    class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
                  >
                    Order type
                  </p>
                  <p class="leading-[32px] font-inter" v-show="!isOutright">
                    Finance
                  </p>
                  <p class="leading-[32px] font-inter" v-show="isOutright">
                    Buy outright
                  </p>
                </div>
                <div class="between">
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
                  >
                    Total
                  </p>
                  <p
                    v-show="!isOutright"
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ totDownPayment }}
                  </p>
                  <p
                    v-show="isOutright"
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ totPrice | formatCurrency }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-10 lg:mt-0">
          <h5
            class="text-[#222222] font-inter text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)] leading-[35px] tracking-[0.26px]"
          >
            Your Payment Options
          </h5>
          <h5
            class="text-[#222222] font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)] my-6 leading-[24px] tracking-[0.26px]"
          >
            Select your payment option:
          </h5>

          <button
            :class="{
              'bg-[#FFFFFF] border-2 border-[#05141F] shadow-[0px,0px,0px,3px,#05141F]':
                !isOutright,
              'bg-[#F7F7F7] border border-black border-opacity-[12.5%]':
                isOutright,
            }"
            class="between lg:grid lg:grid-cols-2 p-[clamp(1rem,0.646vw+0.758rem,1.688rem)] rounded-md items-center w-full"
            @click="isOutright = false"
          >
            <h5
              class="font-inter text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)] leading-[35px] tracking-[0.26px] text-[#222222] font-semibold text-left"
            >
              Finance
            </h5>

            <div class="space-y-[6.5px] text-right">
              <p
                class="text-[#222222] font-inter text-[clamp(1rem,0.294vw+0.89rem,1.313rem)] leading-[26.25px] tracking-[0.26px]"
              >
                {{ totalMonths }}
                <span
                  class="leading-[16.25px] text-[clamp(0.75rem,0.059vw+0.728rem,0.813rem)]"
                  >/month</span
                >
              </p>
              <p
                class="text-[#222222] font-inter text-[12px] leading-[13.75px] tracking-[0.26px]"
              >
                incl. taxes & fees, destination
              </p>
              <p
                class="text-[#222222] font-inter text-[clamp(0.688rem,0.117vw+0.644rem,0.813rem)] leading-[16.25px] tracking-[0.26px]"
              >
                {{ totDownPayment }} down, 60 months
              </p>
            </div>
          </button>

          <button
            :class="{
              'bg-[#FFFFFF] border-2 border-[#05141F] shadow-[0px,0px,0px,3px,#05141F]':
                isOutright,
              'bg-[#F7F7F7] border border-black border-opacity-[12.5%]':
                !isOutright,
            }"
            class="between lg:grid lg:grid-cols-2 p-[clamp(1rem,0.646vw+0.758rem,1.688rem)] rounded-md items-center mt-[clamp(1rem,0.646vw+0.758rem,1.688rem)] w-full"
            @click="isOutright = true"
          >
            <h5
              class="font-inter text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)] leading-[35px] tracking-[0.26px] text-[#222222] font-semibold text-left"
            >
              Buy Outright
            </h5>

            <div class="space-y-[6.5px] text-right">
              <p
                class="text-[#222222] font-inter text-[clamp(1rem,0.294vw+0.89rem,1.313rem)] leading-[26.25px] tracking-[0.26px]"
              >
                {{ totPrice | formatCurrency }}
              </p>
              <p
                class="text-[#222222] font-inter text-[12px] leading-[13.75px] tracking-[0.26px]"
              >
                incl. taxes & fees, destination
              </p>
            </div>
          </button>

          <!-- Order form -->
          <form class="mt-[clamp(2.375rem,1.995vw+1.627rem,4.5rem)]">
            <h4
              class="font-inter text-[#222222] leading-[35px] tracking-[0.26px] text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)]"
            >
              Order Form
            </h4>

            <div class="mt-6 space-y-6">
              <div>
                <label>
                  <span
                    class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                    >Full Name</span
                  >
                  <input
                    type="text"
                    v-model="fullName"
                    placeholder="ex. John Doe"
                    class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                  />
                </label>
              </div>
              <div>
                <label>
                  <span
                    class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                    >Email</span
                  >
                  <input
                    type="email"
                    v-model="email"
                    placeholder="ex. John@example.com"
                    class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                  />
                </label>
              </div>
              <div>
                <label>
                  <span
                    class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                    >Phone Number</span
                  >
                  <input
                    type="number"
                    v-model="tel"
                    placeholder="ex. +2349012345678"
                    class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                  />
                </label>
              </div>
              <p
                class="leading-[35px] text-[clamp(1rem,0.469vw+0.824rem,1.5rem)] tracking-[0.26px] font-inter text-[#222222]"
              >
                Address
              </p>
              <div class="grid grid-cols-2 gap-x-3 gap-y-6">
                <div>
                  <label>
                    <span
                      class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                      >Street</span
                    >
                    <input
                      type="text"
                      v-model="street"
                      placeholder="ex. street"
                      class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <span
                      class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                      >City</span
                    >
                    <input
                      type="text"
                      v-model="city"
                      placeholder="ex. Lagos"
                      class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <span
                      class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                      >State</span
                    >
                    <input
                      type="text"
                      v-model="state"
                      placeholder="ex. Lagos"
                      class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <span
                      class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                      >Postal code <span class="text-xs">(optional)</span></span
                    >
                    <input
                      type="number"
                      v-model="postalCode"
                      placeholder="ex. 110300"
                      class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                    />
                  </label>
                </div>
              </div>
              <div v-show="!isOutright">
                <label>
                  <span
                    class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                    >Down Payment</span
                  >
                  <input
                    disabled
                    type="text"
                    v-model="totDownPayment"
                    class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                  />
                </label>
              </div>
              <div v-show="!isOutright">
                <label>
                  <span
                    class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                    >Financing Duration</span
                  >
                  <input
                    disabled
                    type="text"
                    v-model="duration"
                    class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                  />
                </label>
              </div>
              <div>
                <label>
                  <span
                    class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                    >Delivery Options</span
                  >
                  <select
                    class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                    v-model="deliveryOption"
                  >
                    <option value="" disabled selected>Select an option</option>
                    <option value="Pick-up">Pick-up</option>
                    <option value="Deliver to home">Deliver to home</option>
                  </select>
                </label>
              </div>
              <div>
                <label>
                  <span
                    class="leading-[19.6px] text-black font-inter text-[clamp(0.875rem,0.117vw+0.831rem,1rem)]"
                    >Notes <span class="text-xs">(optional)</span></span
                  >
                  <input
                    type="text"
                    v-model="note"
                    placeholder="Enter note"
                    class="w-full border border-[#8484846f] p-[15px] mt-2 rounded-lg text-black text-sm font-inter leading-[16.94px]"
                  />
                </label>
              </div>
            </div>

            <div
              class="lg:hidden border-t border-black border-opacity-[12.5%] pt-6 mt-6"
            >
              <h4
                class="font-inter text-[#222222] leading-[35px] tracking-[0.26px] text-[clamp(1.125rem,0.587vw+0.905rem,1.75rem)]"
              >
                Order Summary
              </h4>

              <div class="mt-4 space-y-4">
                <div class="between" v-show="!isOutright">
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
                  >
                    Down payment amount
                  </p>
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ downPayment }}
                  </p>
                </div>
                <div class="between" v-show="isOutright">
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
                  >
                    Vehicle price
                  </p>
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ price | formatCurrency }}
                  </p>
                </div>

                <div class="between">
                  <p
                    class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
                  >
                    Number of vehicles
                  </p>
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ number }}
                  </p>
                </div>
                <div class="between">
                  <p
                    class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
                  >
                    Colour(s)
                  </p>
                  <div v-if="sho" class="w-4/5 flex-wrap gap-3 mt-1 end">
                    <div
                      v-for="(select, index) in selected"
                      :key="index + 'selected' + select"
                    >
                      <div>
                        <img
                          :src="spectrum[select - 1].icon"
                          alt=""
                          class="w-5 h-5 overflow-hidden rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="between">
                  <p
                    class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] font-inter text-[#4F4F51A6]"
                  >
                    Order type
                  </p>
                  <p class="leading-[32px] font-inter" v-show="!isOutright">
                    Finance
                  </p>
                  <p class="leading-[32px] font-inter" v-show="isOutright">
                    Buy outright
                  </p>
                </div>
                <div class="between">
                  <p
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[23px] font-semibold font-inter text-[#4F4F51A6]"
                  >
                    Total
                  </p>
                  <p
                    v-show="!isOutright"
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ totDownPayment }}
                  </p>
                  <p
                    v-show="isOutright"
                    class="text-[clamp(0.875rem,0.235vw+0.787rem,1.125rem)] leading-[32px] font-semibold font-inter"
                  >
                    {{ totPrice | formatCurrency }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="border-t border-black border-opacity-[12.5%] mt-[30px] pt-[25px] space-y-6"
            >
              <div
                class="bg-[#FFF6E5] border border-[#FFA500] rounded-lg p-[15px] start space-x-2"
              >
                <img
                  src="@/assets/svgs/warning.svg"
                  class="w-[19.5] h-[19.5]"
                  alt="warning"
                />
                <p
                  class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] font-inter leading-[16.94px]"
                >
                  Please note: Delivery will take a <br class="md:hidden" />
                  minimum of 60 to 90 days
                </p>
              </div>

              <div class="space-x-2.5 start">
                <input type="checkbox" id="checkbox" v-model="checked" />
                <label
                  for="checkbox"
                  class="text-[clamp(0.75rem,0.117vw+0.706rem,0.875rem)] leading-[23px] text-[#4F4F51] font-inter cursor-pointer"
                  >I agree to the Terms and Conditions</label
                >
              </div>

              <button
                class="!w-full black-btn long"
                type="submit"
                :disabled="!isFormValid || sending"
                @click.prevent="submit"
              >
                {{ sending ? "Sending Request..." : "Submit Order" }}
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import col1 from "@/assets/images/savannah/colors/night_black.png";
import col2 from "@/assets/images/savannah/colors/dust-free_silver.png";
import col3 from "@/assets/images/savannah/colors/particle_blue.png";
import col4 from "@/assets/images/savannah/colors/cornet_white.png";
// import col5 from "@/assets/images/savannah/colors/5.png";
export default {
  data() {
    return {
      all: false,
      success: false,
      sending: false,
      lhero: false,
      isOutright: false,
      sho: true,
      number: 1,
      price: 64159035.46,
      monthly: 1868186.41,
      fullName: "",
      email: "",
      tel: "",
      street: "",
      city: "",
      state: "",
      checked: false,
      postalCode: "",
      duration: "5 years",
      deliveryOption: "",
      note: "",
      spectrum: [
        { icon: col1, name: "Night Black" },
        { icon: col2, name: "Dust-Free Silver" },
        { icon: col3, name: "Particle Blue" },
        { icon: col4, name: "Cornet White" },
        // { icon: col5, name: "Red" },
      ],
      selected: [1],
      // isFormValid: false,
    };
  },
  computed: {
    showNumbering() {
      return this.selected.length > 1;
    },
    downPayment() {
      return this.$options.filters.formatCurrency(this.price * 0.1);
    },
    totPrice() {
      return this.price * this.number;
    },
    totDownPayment() {
      return this.$options.filters.formatCurrency(this.totPrice * 0.1);
    },
    totalMonths() {
      return this.$options.filters.formatCurrency(this.monthly * this.number);
    },
    isFormValid() {
      return (
        this.fullName !== "" &&
        this.email !== "" &&
        this.tel !== "" &&
        this.street !== "" &&
        this.city !== "" &&
        this.state !== "" &&
        this.downPayment !== "" &&
        this.duration !== "" &&
        this.checked !== false &&
        this.deliveryOption !== ""
      );
    },
    payload() {
      return {
        fullName: this.fullName,
        email: this.email,
        tel: this.tel,
        street: this.street,
        city: this.city,
        state: this.state,
        postalCode: this.postalCode,
        downPayment: this.totDownPayment,
        total: this.isOutright
          ? this.$options.filters.formatCurrency(this.totPrice)
          : this.totDownPayment,
        duration: this.duration,
        deliveryOption: this.deliveryOption,
        note: this.note,
        quantity: this.number,
        price: this.$options.filters.formatCurrency(this.totPrice),
        colours: this.selected
          .map((colourIndex) => this.spectrum[colourIndex - 1].name)
          .join(", "),
        paymentOption: this.isOutright ? "Outright" : "Finance",
        monthly: this.totalMonths,
      };
    },
  },
  methods: {
    closeSuccess() {
      this.success = false;
      this.fullName = "";
      this.email = "";
      this.tel = "";
      this.street = "";
      this.city = "";
      this.state = "";
      this.checked = false;
      this.postalCode = "";
      this.deliveryOption = "";
      this.note = "";
      this.number = 1;
      this.sho = false;
      this.sho = true;
      this.selected = [1];
      this.isOutright = false;
    },
    handleColourSelect(setIndex, colourIndex) {
      var existing = this.selected;
      if (setIndex !== 0 && this.all && colourIndex + 1 !== existing[0]) {
        this.all = false;
      }
      existing[setIndex] = colourIndex + 1;
      if (setIndex === 0 && this.all) {
        for (let index = 0; index < existing.length; index++) {
          this.selected[index] = colourIndex + 1;
        }
      }
      this.selected = existing;
      // NOT A MISTAKE------DO NOT DELETE OR COMMENT
      this.sho = false;
      this.sho = true;
      // NOT A MISTAKE------DO NOT DELETE OR COMMENT
    },
    async submit() {
      // if (this.validateForm() === true) {
      if (!this.sending) {
        this.sending = true;
        try {
          // console.log('>>>',this.payload)
          // return
          await fetch(
            process.env.VUE_APP_API_URL + "/savannah-orders/data.json",
            {
              method: "POST",
              body: JSON.stringify({ ...this.payload, created_at: new Date() }),
            }
          )
            .then((res) => res.json())
            .then(async (response) => {
              // console.log(response);
              var body = {
                form: this.isOutright
                  ? "panther_order_outright"
                  : "panther_order",
                payload: {
                  vehicle: "Savannah",
                  year: 2024,
                  user_mail: this.payload.email,
                  customerName: this.payload.fullName,
                  qty: this.payload.quantity,
                  colours: this.payload.colours,
                  payment_option: this.payload.paymentOption,
                  monthly: `${this.payload.monthly}`,
                  downpayment: `${this.payload.downPayment}`,
                  total: this.payload.total,
                  address: `${this.payload.street}, ${this.payload.city}, ${this.payload.state}`,
                },
              };
              await this.$store.dispatch("sendMail", body);
              this.success = true;
              // this.success();
              // alert(
              //   "Request Submitted! \n You will be contacted by our sales team within 24 hours"
              // );
            })
            .catch((err) => {
              alert("something went wrong, please try again 1");
            });
        } catch (error) {
          alert("something went wrong, please try again 2");
          console.log(error);
        }
        this.sending = false;
      }
      // }
    },
    handleSubmit(event) {
      event.preventDefault();

      const formData = this.getFormData();
      // Send formData to server or API
      console.log(formData);
    },
  },
  watch: {
    number() {
      if (this.number > 0) {
        if (this.number > this.selected.length) {
          var diff = this.number - this.selected.length;
          for (let index = 1; index <= diff; index++) {
            this.selected.push(this.all ? this.selected[0] : 1);
          }
          console.log(this.selected);
        } else if (this.number < this.selected.length) {
          this.selected = this.selected.slice(0, this.number);
        }
      }
    },
    all() {
      if (this.all === true) {
        var first = this.selected[0];
        for (let index = 0; index < this.selected.length; index++) {
          this.selected[index] = first;
        }
      }
    },
  },
  filters: {
    formatCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
};
</script>

<style></style>
